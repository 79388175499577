// @flow

import * as React from "react";
import * as S from "./styled";
import { Formik, Field, Form } from "formik";
import { Card, Form as FormTabler, Button, Alert } from "tabler-react";
import { useAuth } from '@src/hooks/useAuth';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { userLogin, userLoginToToken } from '@src/features/auth/authActions';

export function SignInPage(props) {
  const { loading, userInfo, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const auth = useAuth();
  const history = useHistory();

  React.useEffect(() => {
    if (userInfo) {
      auth.signing(userInfo, (res) => {
        if (res) {
          dispatch(userLoginToToken({}));
          history.push('/dashboard');
        }
      });
    }
  }, [userInfo, auth, dispatch, history]);

  const handleSubmit = (data) => {
    dispatch(userLogin(data));
  };

  return (
    <div className="login-page">
      <S.Wrapper>
        <Card>
          <Card.Header>
            <Card.Title>Sign In</Card.Title>
          </Card.Header>
          <Card.Body>
            {error && <Alert type="danger">{error}</Alert>}
            <Formik
              initialValues={{ email: '', password: '' }}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <FormTabler.Group>
                    <FormTabler.Label>Email</FormTabler.Label>
                    <Field name="email" type="email" className="form-control" />
                  </FormTabler.Group>
                  <FormTabler.Group>
                    <FormTabler.Label>Password</FormTabler.Label>
                    <Field name="password" type="password" className="form-control" />
                  </FormTabler.Group>
                  <Button type="submit" disabled={isSubmitting || loading} color="primary">
                    {loading ? 'Loading...' : 'Sign In'}
                  </Button>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </S.Wrapper>
    </div>
  );
}