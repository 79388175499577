import { createSlice } from '@reduxjs/toolkit'
import { profileUpdate, profileChangePassword } from './profileActions'

const initialState = {
  loading: false,
  error: null,
  success: false
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: {
    // login user
    [profileUpdate.pending]: (state) => {
      state.success = false
      state.loading = true
      state.error = null
    },
    [profileUpdate.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
    },
    [profileUpdate.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },

    
    [profileChangePassword.pending]: (state) => {
      state.success = false
      state.loading = true
      state.error = null
    },
    [profileChangePassword.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
    },
    [profileChangePassword.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
})

export const { logout, setCredentials } = profileSlice.actions

export default profileSlice.reducer