import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '@src/features/auth/authSlice';

export function LogoutPage(props) {

  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout())

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!userInfo) {
      window.location.href = "/";
    }

    // eslint-disable-next-line
  }, [userInfo])

  return (
    <>
    </>
  )
}
