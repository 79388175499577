import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "@src/services/api";

export const listNotifications = createAsyncThunk(
  "notifications/list",
  async ({ params }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        params,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await api.get("/notifications", config);

      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const unreadNotifications = createAsyncThunk(
  "notifications/unread",
  async ({ notifications }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await api.put(
        "/notifications/unread",
        { data: notifications },
        config
      );

      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
