import { createSlice } from "@reduxjs/toolkit";
import { listNotifications, unreadNotifications } from "./notificationsActions";

const initialState = {
  loading: false,
  error: null,
  success: true,
  notifications: [],
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: {
    // list notifications
    [listNotifications.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [listNotifications.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.notifications = payload;
    },
    [listNotifications.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },


    [unreadNotifications.pending]: (state) => {
      state.loading = true;
      state.success = false;
      state.error = null;
    },
    [unreadNotifications.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
    },
    [unreadNotifications.rejected]: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = payload;
    },
  },
});

export const { logout, setCredentials } = notificationSlice.actions;

export default notificationSlice.reducer;
