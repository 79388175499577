import { createSlice } from "@reduxjs/toolkit";
import {
  listDashboard
} from "./dashboardActions";

const initialState = {
  loading: false,
  dashboard: null,
  error: null,
  success: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: {
    // dashboard infos
    [listDashboard.pending]: (state) => {
      state.success = false;
      state.loading = true;
      state.error = null;
    },
    [listDashboard.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dashboard = payload;
    },
    [listDashboard.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

// eslint-disable-next-line
export const {} = dashboardSlice.actions;

export default dashboardSlice.reducer;
