// @flow
import React, { useEffect, useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { Site, RouterContextProvider, Dimmer } from "tabler-react";

const navBarItems = [
  {
    value: "Dashboard",
    to: "/dashboard",
    icon: "home",
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
];

const accountDropdownProps = (nav, user) => {
  return {
    avatarURL: user?.avatar_url || "https://via.placeholder.com/32x32",
    name: user?.name,
    description: "Administrator",
    options: [
      {
        icon: "user",
        value: "Perfil",
        to: "#",
        onClick: () => {
          nav.push("/dashboard/profile");
        },
      },
      {
        icon: "settings",
        value: "Configuração",
        to: "#",
        onClick: () => {
          nav.push("/dashboard/settings");
        },
      },
      { isDivider: true },
      {
        icon: "log-out",
        value: "Sign out",
        onClick: () => {
          nav.push("/dashboard/logout");
        },
      },
    ],
  };
};

const SiteWrapper = (props) => {
  const { userInfo, loading } = useSelector((state) => state.auth);
  const [unreadCount, setUnreadCount] = useState(false);
  const [notificationsObjects, setNotificationsObjects] = useState([]);

  const handleNotificationsUnread = () => {
    // const data = notificationsObjects.map((item) => item.id);
    setUnreadCount(false);
    // dispatch(unreadNotifications({ notifications: data }));
  };

  return (
    <Dimmer active={loading} loader={loading}>
      <Site.Wrapper
        headerProps={{
          href: "/",
          alt: "Tax Follow Up",
          imageURL: "./images/logo.svg",
          notificationsTray: {
            notificationsObjects,
            markAllAsRead: handleNotificationsUnread,
            unread: unreadCount,
          },
          accountDropdown: accountDropdownProps(props.history, userInfo?.user),
        }}
        navProps={{ itemsObjects: navBarItems }}
        routerContextComponentType={withRouter(RouterContextProvider)}
        footerProps={{
          copyright: (
            <React.Fragment>
              Copyright © 2023
              <a
                href="https://taxfollowup.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Tax Follow Up
              </a>{" "}
              All rights reserved.
            </React.Fragment>
          ),
        }}
      >
        {props.children}
      </Site.Wrapper>
    </Dimmer>
  );
};

export default withRouter(SiteWrapper);
