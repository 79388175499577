import axios from 'axios';
import env from "../configuration";

export const URL_API = `${env.API_URL}/api`;
const version = '/v2';

const api = axios.create({
  baseURL: `${URL_API}${version}`,
});

api.interceptors.request.use((config) => {
  const token = window.localStorage.getItem("userToken")

  const headers = { ...config.headers };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return { ...config, headers };
});

export default api;