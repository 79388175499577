import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "@src/services/api";

export const profileUpdate = createAsyncThunk(
  "profile/update",
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("data", JSON.stringify(data));

      if (data?.files?.length) {
        // eslint-disable-next-line
        data.files.map((file) => {
          formData.append(
            `files.${file.key}`,
            file.buffer,
            file.buffer.fileName
          );
        });
      }

      await api.put("/users-permissions/users/me", formData);

      return true;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const profileChangePassword = createAsyncThunk(
  "profile/changePassword",
  async (request, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await api.post("/auth/change-password", request, config);

      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
