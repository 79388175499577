import * as React from "react";
import { ProvideAuth } from './hooks/useAuth';
import { PrivateRoute, PublicRoute } from "./routes";

import {
  BrowserRouter as Router,
  Switch
} from "react-router-dom";

import {
  LogoutPage
} from "./pages/Dashboard";


// Pages
import {
  SignInPage
} from "./pages/Authentication";

import HomePage from "./HomePage.react";

// Css
import "tabler-react/dist/Tabler.css";
import "react-toggle/style.css"
import "./App.css";


function RedirectHome() {
  return <></>
}


function App(props) {
  return (
    <React.StrictMode>
      <ProvideAuth>
        <Router>
          <Switch>

            {/* Initial */}
            <PublicRoute exact path="/">
              <RedirectHome />
            </PublicRoute>

            {/* Authentication */}
            <PublicRoute exact path="/login">
              <SignInPage />
            </PublicRoute>
            <PrivateRoute exact path="/dashboard">
              <HomePage />
            </PrivateRoute>

            {/* Logout */}
            <PrivateRoute path="/dashboard/logout">
              <LogoutPage />
            </PrivateRoute>
          </Switch>
        </Router>
      </ProvideAuth>
    </React.StrictMode>
  );
}

export default App;
