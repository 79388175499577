import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { userLoginToToken } from '@src/features/auth/authActions';
import { useLocalStorage } from "@src/hooks/useLocalStorage";
import { useAuth } from "@src/hooks/useAuth";
import { useHistory, Route, Redirect } from "react-router-dom";

export function PublicRoute({ children, ...rest }) {
  const auth = useAuth();
  const history = useHistory();
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [tokenSave] = useLocalStorage("userToken");

  useEffect(() => {
    if (tokenSave && !auth.user) {
      dispatch(userLoginToToken({}));
    }
  }, [tokenSave, auth.user, dispatch]);

  useEffect(() => {
    if (userInfo) {
      auth.signing(userInfo, (res) => {
        if (res) {
          history.push('/dashboard');
        }
      });
    }
  }, [userInfo, auth, history]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
