import React, { useState, useContext, createContext } from "react";
import env from "../configuration";
import { useLocalStorage } from "@src/hooks/useLocalStorage";
import { useSocket } from 'socket.io-react-hook';

const authContext = createContext();

export const useAuthenticatedSocket = () => {
  const [tokenSave] = useLocalStorage("userToken");
  return useSocket(env.API_URL, {
    enabled: !!tokenSave,
    auth: {
      token: tokenSave
    }
  });
};

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signing = (userInfo, cb) => {
    setUser(userInfo);
    cb(true);
  };

  const signup = (cb) => {
    cb()
  };

  const signout = (cb) => {
    setUser("");
    cb();
  };

  const sendPasswordResetEmail = email => {

  };

  const confirmPasswordReset = (code, password) => {

  };

  return {
    user,
    signing,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset
  };
}