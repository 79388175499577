import { createAsyncThunk } from '@reduxjs/toolkit'
import api from "@src/services/api";


export const listDashboard = createAsyncThunk(
  'dashboard/list',
  async ({ params = {} }, { rejectWithValue }) => {
    console.log('step0');
    try {
      console.log('step1');
      // configure header's Content-Type as JSON
      const config = {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await api.get(
        '/dashboard',
        config
      )

      console.log(data);

      if(data.success) {
        return data.data
      }

      return rejectWithValue(data.message)
    } catch (error) {
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

