import * as React from "react";

import { Container } from "tabler-react";

import { useAuth } from "@src/hooks/useAuth";

// Components
import SiteWrapper from "@src/SiteWrapper.react";

import {
  Route,
  Redirect
} from "react-router-dom";


export function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location, match, ...props }) => {
        if (auth && auth.user) {
          if (match.path === "/dashboard/planner/:id") {
            return (
              <SiteWrapper>
                {children}
              </SiteWrapper>
            );
          }
          return (
            <SiteWrapper>
              <Container>
                {children}
              </Container>
            </SiteWrapper>
          );
        }

        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }}
    />
  );
}
