import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "@src/services/api";

export const userLogin = createAsyncThunk(
  "user/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await api.post(
        "/auth/login",
        {
          username: email,
          password,
        },
        config
      );

      if(data.success) {
        // store user's token in local storage
        localStorage.setItem("userToken", data.data.token);
  
        return data;
      }

      return rejectWithValue(data.data.message);

    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userLoginToToken = createAsyncThunk(
  "user/loginToken",
  // eslint-disable-next-line
  async ({}, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await api.get("/user/me", config);

      // store user's token in local storage
      // localStorage.setItem('userToken', data.jwt)
      if(data.success) {
        return { user: data.data };
      }

      return rejectWithValue(data.message);
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const registerUser = createAsyncThunk(
  "user/register",
  async ({ firstName, email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.post(`auth/local`, { firstName, email, password }, config);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
