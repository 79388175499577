import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '@src/features/counter/counterSlice';
import authReducer from '@src/features/auth/authSlice';
import dashboardReducer from '@src/features/dashboard/dashboardSlice';
import profileReducer from '@src/features/profile/profileSlice';
import notificationsReducer from '@src/features/notifications/notificationsSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    dashboard: dashboardReducer,
    profile: profileReducer,
    notifications: notificationsReducer,
  },
});
